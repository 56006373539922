$(document).ready(function() {

  //Stellarnav
  $('.stellarnav').stellarNav({
    theme: 'plain',
    breakpoint: 880,
    menuLabel: 'Menu',
    sticky: true,
    position: 'right',
    openingSpeed: 250,
    closingDelay: 250,
    showArrows: true,
    phoneBtn: '',
    locationBtn: '',
    closeBtn: false,
    scrollbarFix: false,
    mobileMode: false,
  });



  //Back to top
  $(window).scroll(function() {
    var v = $(window).scrollTop();
    $("#totop").toggleClass("scroll", v > 200);
  });
  $("#totop").click(function() {
    $("html, body").animate({
      scrollTop: 0
    }, 750);
    return false;
  });

  //Login button
  $('#login_button').on('click', function() {
    $('form#login').submit();
  });
  $('#login_mobile_button').on('click', function() {
    var url = $(this).data('link');
    location.assign(url);
  })
  $('#send_contact').on('click', function() {
    $('.g-recaptcha').click();
  })

  //Tooltipster
  $('.tooltip').tooltipster({
    theme: 'tooltipster-shadow',
    trigger: 'click',
    interactive: true,
    animation: 'fade',
    animationDuration: 500,
    minWidth: 400,
    maxWidth: 500,
    side: 'bottom',
    functionReady: function() {
      $('#mdp_oublie').css('display', 'block');
      $('#block_mdp_oublie').css('display', 'none');
      $('#mdp_oublie_error').css('display', 'none');
      $('.tooltip').tooltipster('reposition');
    }
  });
  $('#close-tooltipser').on('click', function() {
    $('.tooltip').tooltipster('close');
  });

  //Sumoselect
  $('select').SumoSelect({
    placeholder: '&nbsp;',
    floatWidth: 880,
    forceCustomRendering: true,
    nativeOnDevice: ['Android', 'BlackBerry', 'iPhone', 'iPad', 'iPod', 'Opera Mini', 'IEMobile', 'Silk'],
    locale: ['OK', 'Annuler', 'Tout sélectionner']
  });


  //form abonnement
  $('#submit_form_abonnement').on('click', function() {
    $('#form_abonnement').submit();
  });



  //MDP Oublié
  $('#mdp_oublie').on('click', function() {
    $('#block_mdp_oublie').toggle();
    $('#mdp_oublie').toggle();
  });

  $('#change_mdp_submit').on('click', function(_f) {
    $('#mdp_oublie_error').css('display', 'none');
    let newEmail = $("#email").val();
    $.ajax("/ajax/nouveau-mdp-pro.php?site=EAGD&email=" + newEmail, {
      "success": _cb_password,
      "error": _cb_password
    });
  });

  function _cb_password(_data, _status, _xhr) {
    if (_status != 'success') {
      $('#mdp_oublie_error').css('display', 'block');
      $('#mdp_oublie_error').html("Erreur de communication.");
    } else if (!_data.rc) {
      $('#mdp_oublie_error').css('display', 'block');
      $('#mdp_oublie_error').html(_data.msg);
    } else {
      $('#mdp_oublie_error').css('display', 'block');
      $('#mdp_oublie_error').css('color', 'green');
      $('#mdp_oublie_error').html("Nouveau mot de passe envoy&eacute;");
    }
  }

});


//contact
function submit_form() {
  document.getElementById('contact_form').submit();
}